import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import {useLocation,Link,useParams} from 'react-router-dom';
import {displayErrorToast,parseBoolean,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getCompanyDetails} from '../../services/CompanyServices';
import { getCustomerMainDetails} from '../../services/CustomerMainServices';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import SummaryIcon from '../../components/SummaryIcon';
import { getSalesOrderDetails, getSalesOrdersForWorkSchedule,getAllSalesOrder} from '../../services/SalesOrderServices';
import { getCustomerSiteDetails,getAllCustomerSites } from '../../services/CustomerSiteServices';
import { updateWorkSchedule,getWorkScheduleDetails,getWorkScheduleJobNumber,deleteWorkSchedule } from '../../services/WorkScheduleServices';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import { getDisplayDate } from '../../utils/DateUtils';
function WorkScheduleEdit() {
    const location = useLocation();
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [inProgress, setInProgress] = useState(false);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({});
    const [filteredplants,setFilteredPlants] = useState([]);
    const [products, setProducts] = React.useState([]);
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [salesorders,setSalesorders]=React.useState([]);
    const [selectedSalesOrder,setSelectedSalesOrder] = useState({});
    const [deliverymodes,setDeliveryModes] = useState([]);
    const [allSalesOrders,setAllSalesOrders] = useState([]);
    const [sites, setSites] = React.useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isLoading, setIsLoading] = useState(true);  
    const [selectedCustomer,setSelectedCustomer]=React.useState({})
    const [selectedSite,setSelectedSite]= React.useState({})
    const [userId] = useCookies(['myUserId']);
    React.useEffect(() => {
      setInputs(values => ({...values,
          ['schedule_date']: getCurrentDate(), 
          ['delivery_date']: getCurrentDate(),
          ['delivery_time']: getCurrentTime(),
          ['is_extra_shut_required']:'false',
          ['cube_mould_test_place']:'plant',
          ['is_name_req']:'false'
      }))
    }, []);
 
    React.useEffect(() => {
      getAllSalesOrder(cookies)
      .then (
          salesorderList => {
              const salesorders = salesorderList.sales_order_list.filter(obj => obj.status).map(
                  salesorder => {
                      return { value: salesorder.id, label: salesorder.prefix + salesorder.order_no}
                  }
              );
              setSalesorders(salesorders);
              setAllSalesOrders(salesorderList.sales_order_list.filter(obj => obj.status))
          }
      )
    }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
        setInputs(values => ({...values, [name]: value}));
        if(name === 'plant_id' && value){
            setIsLoading(true);
            getWorkScheduleJobNumber(cookies,value)
            .then(work_schedule => {
                setIsLoading(false);
                setInputs(values => ({...values, ['schedule_no']: work_schedule.schedule_no}));
            })
            .catch((error) =>
            {
                setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
            })
        } else if (name === 'plant_id' && !value) {
            setInputs(values => ({...values, ['schedule_no']: ''}));
        }
        // else if (name === 'delivery_date')
        // {
        //     // Parse the delivery date string to a Date object
        //     var deliveryDate = new Date(value);
        //     console.log(deliveryDate+"deliveryDate");

        //     // Add 1 day to the delivery date
        //     var validityDate = new Date(deliveryDate);
        //     console.log(validityDate+"validityDate");
        //     validityDate.setDate(deliveryDate.getDate() + 1);

        //     // Format the validity date as YYYY-MM-DD
        //     console.log(validityDate.toISOString()+"validityDate.toISOString()")
        //     var formattedValidityDate = validityDate.toISOString().split('T')[0];
        //     console.log(formattedValidityDate+"formattedValidityDate");

        //     setInputs(values => ({...values, ['validity_date']: formattedValidityDate}));
        // }
    }
    catch (e) {
      displayErrorToast(e);
    }
  }
  
  const handleChangeSalesOrderDetails = (event) => {
    console.log(event.target.value);
    const salesorderId = event.target.value;
    const propertyName = event.target.name;
    console.log(salesorderId+"salesorderId")
    
    if (salesorderId) {
        setInputs(values =>({...values,[propertyName]:salesorderId}));
        const selected_so = allSalesOrders.filter(obj => obj.id == salesorderId)[0];
        setSelectedSalesOrder(selected_so);
      //Loading production grade dropdown
      setProducts(selected_so.order_list  
        .filter(order => order.product.category.name === 'FINISHED GOODS')
        .map(order => ({value : order.id,label : order.product.name})));
        setIsLoading(true);
      getCompanyDetails(cookies,selected_so.company.id)  //get plant list
      .then(company =>{
        setIsLoading(false);
        setFilteredPlants(company.plants.map( plant =>{
            return {value: plant.id,label : plant.alias}
        }));
         })
    } 
  } 
 
  const handleChangeProductDetails = (event) =>{
    const DM_MANUAL = 'manual';
    const DM_PUMP = 'pump';
    const DM_MANUAL_OR_PUMP = 'manual/pump';

    const sales_order_detail_id = event.target.value;
    const propertyName = event.target.name;
    
    if (sales_order_detail_id) {
        let selected_product = selectedSalesOrder.order_list.filter( order => order.id == sales_order_detail_id)[0];
        let balanceQty = selected_product.quantity-selected_product.delivered_qty-selected_product.scheduled_qty
        setInputs(values =>({...values,
            [propertyName]:sales_order_detail_id,
            ['delivery_mode']:(selected_product.delivery_mode === DM_MANUAL_OR_PUMP ? '' : selected_product.delivery_mode),
            ['order_quantity']:selected_product.quantity,
            ['balance_qty']:balanceQty
        }));
        document.getElementById('scheduled_qty').max = balanceQty;

        if (selected_product.delivery_mode === 'manual/pump') {
            setDeliveryModes([
                {value: '' ,label:'Please select Delivery Mode'},
                {value: DM_MANUAL ,label:DM_MANUAL.toUpperCase()},
                {value: DM_PUMP ,label:DM_PUMP.toUpperCase()}
            ])
        } else {
            setDeliveryModes([{value: selected_product.delivery_mode ,label:selected_product.delivery_mode.toUpperCase()}])
        }
    }
   } 
   
      const navigate = useNavigate();
      const handleChangeCementDisplyed = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        const FIELD_CEMENT_NAME='cement_name';
        if(value === 'false'){
           document.getElementById(FIELD_CEMENT_NAME).disabled = true;
           document.getElementById(FIELD_CEMENT_NAME).required = false;
           document.getElementById(FIELD_CEMENT_NAME).className = document.getElementById(FIELD_CEMENT_NAME).className.replace('mandatory-form-control',''); 
        }
        else
        {
            document.getElementById(FIELD_CEMENT_NAME).disabled = false;
            document.getElementById(FIELD_CEMENT_NAME).required = true;
            document.getElementById(FIELD_CEMENT_NAME).className += ' mandatory-form-control'; 
        }
        setInputs(values => ({...values, [name]: event.target.value}))
    }

  
    const showCustomerDetails = () => {

        if (selectedSalesOrder.id) {
            getCustomerMainDetails(cookies,selectedSalesOrder.consignee.customer.id)
            .then(selectedCustomer => {
                Swal.fire({
                    html:getHTMLForSummaryPopup(
                        'BILLING DETAILS',
                        [
                        {label: 'ADDRESS', value: selectedCustomer.address_1},
                        {value: selectedCustomer.address_2},
                        {value: selectedCustomer.address_3},
                        {label: 'GST', value: selectedCustomer.gst_no},
                        {label: 'PAN', value: selectedCustomer.pan_no},
                        {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name},
                        {label: 'CREDIT LIMIT', value: selectedCustomer.credit_limit}
                        ])
                }, '', 'info');
            })
        }
   }

   const showSiteDetails = () => {
    if (selectedSalesOrder.id) {
        getCustomerSiteDetails(cookies,selectedSalesOrder.consignee.id)
        .then(consignee => {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SITE DETAILS',
                    [
                    {label: 'ADDRESS', value: consignee.address_1},
                    {value: consignee.address_2},
                    {value: consignee.address_3},
                    {label: 'SITE NAME', value: consignee.name},
                    {label: 'CONTACT NAME', value: consignee.contact_person},
                    {label: 'MOBILE NO', value: consignee.contact_mobile_no},
                    {label: 'DESIGNATION', value: consignee.contact_designation}
                    ])
            }, '', 'info');   
        })
          
    }
    
}
const showSalesOrderDetails = () => {

    if (selectedSalesOrder.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SALESORDER DETAILS',
                    [
                    {label: 'SO DATE', value: selectedSalesOrder.order_date},
                    {label: 'MODE OF TRANSPORT', value: selectedSalesOrder.transport_mode},
                    {label: 'DELIVERY DATE', value: selectedSalesOrder.delivery_date},
                    {label: 'REMARKS', value: selectedSalesOrder.user_remarks}
                   
                    ])
            }, '', 'info');   
    }
    
}
const onDeleteWorkSchedule = (event) => {

    event.preventDefault();
    Swal.fire({title: 'Are you sure to Delete?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,  
    cancelButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed) { 

        setIsLoading(true);
        deleteWorkSchedule(cookies, id)
        .then(response => {
            console.log(`Work schedule with id ${id} deleted`);
            setRefreshKey(oldKey => oldKey +1);
            Swal.fire('Deleted Successfully!', '', 'success');
            view();
        })
        .catch(error => {
            setRefreshKey(oldKey => oldKey +1);
            console.log(error.response.data);
            displayError(error.response.data,'Delete Failed');
        }); 
        
        
      } else if (result.isDismissed) {    
        Swal.fire('Not Deleted', '', 'info')  
    }
    });
    //

};

const constructFormData = () => {
    const EMPTY_STRING = '';
    return {
        id :id,
        plant_id:parseInt(inputs.plant_id) ,
        sales_order_detail_id:parseInt(inputs.sales_order_detail_id),
        schedule_no: inputs.schedule_no,
        schedule_date:getDisplayDate(inputs.schedule_date),
        is_name_req:parseBoolean(inputs.is_name_req),
        cement_name: inputs.cement_name,
        delivery_date:getDisplayDate(inputs.delivery_date),
        validity_date:getDisplayDate(inputs.validity_date),
        delivery_time:inputs.delivery_time,
        scheduled_qty:parseFloat(inputs.scheduled_qty),
        is_extra_shut_required:parseBoolean(inputs.is_extra_shut_required),
        delivery_mode: inputs.delivery_mode,
        slump1: parseInt(inputs.slump1),
        slump2: parseInt(inputs.slump2),
        cube_mould_test_place: inputs.cube_mould_test_place
    }
            
} 
 
 
   //If all the required fields are field u can select the save button then choose the option yes or no
   const handleSubmit = (event) => {
    event.preventDefault();
   
    if ((Object.values(error).every(x => !x)) && isSubmitting) { 
        Swal.fire({title: 'Do you want to Update?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) 
            { 
               
              updateWorkSchedule(cookies, constructFormData(),id)
              .then(function (response) {
                Swal.fire('Updated!', '', 'success') 
                view();
              })
              .catch((error) =>
              {
                console.log(error.response.data);
                displayError(error.response.data,"Update Failed");
              })
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not Updated', '', 'info')  
            }
          });
    }
    else
    {
      Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
    }
}
const {id}=useParams()
const FIELD_CEMENT_NAME='cement_name';

React.useEffect(() => {
  if (id) {
    setInProgress(true);
    getWorkScheduleDetails(cookies, id)
      .then(workschedule_object => {
        setInProgress(false);
        setInputs({...workschedule_object, 
            id:workschedule_object.id,
            salesorder_id:workschedule_object.sales_order_detail.sales_order.id,
            plant_id:workschedule_object.plant.id,
            sales_order_detail_id:workschedule_object.sales_order_detail.id,
            delivery_mode:workschedule_object.delivery_mode,
            schedule_no:parseInt(workschedule_object.schedule_no),
            schedule_date:getDisplayDate(workschedule_object.schedule_date),
            is_name_req:parseBoolean(workschedule_object.is_name_req),
            cement_name: workschedule_object.cement_name,
            delivery_date:getDisplayDate(workschedule_object.delivery_date),
            delivery_time:workschedule_object.delivery_time,
            scheduled_qty:parseInt(workschedule_object.scheduled_qty),
            is_extra_shut_required:parseBoolean(workschedule_object.is_extra_shut_required),
            delivery_mode: workschedule_object.delivery_mode,
            slump1: parseInt(workschedule_object.slump1),
            slump2: parseInt(workschedule_object.slump2),
            cube_mould_test_place: workschedule_object.cube_mould_test_place,
            is_schedule_completed:workschedule_object.is_schedule_completed,
            validity_date: getDisplayDate(workschedule_object.validity_date)});

            getCompanyDetails(cookies,workschedule_object.sales_order_detail.sales_order.company.id)  //get plant list
            .then(company =>{
            
                setFilteredPlants(company.plants.map( plant =>{
                    return {value: plant.id,label : plant.alias}
                }));
                })

            setDeliveryModes([{value: workschedule_object.delivery_mode ,label:workschedule_object.delivery_mode.toUpperCase()}])

          getCustomerMainDetails(cookies,workschedule_object.sales_order_detail.sales_order.consignee.customer.id)    
          .then(customerObject => {              //Set the details of the customer in the form fields
              setSelectedCustomer(customerObject)
              })
  
          

          getCustomerSiteDetails(cookies,workschedule_object.sales_order_detail.sales_order.consignee.id)
                .then(customerObject => {                     //Set the details of the customer in the form fields
      
                  
                    setSelectedSite(customerObject);
                });

        getSalesOrderDetails(cookies,workschedule_object.sales_order_detail.sales_order.id)
          .then(salesorderObject =>{
            setSelectedSalesOrder(salesorderObject)
            setProducts(salesorderObject.order_list  
                .filter(order => order.product.category.name === 'FINISHED GOODS')
                .map(order => ({value : order.id,label : order.product.name})));
            
                let selected_product = salesorderObject.order_list  
                .filter(order => order.id === workschedule_object.sales_order_detail.id)[0];
                
              let balanceQty = (selected_product.balance_qty)+(workschedule_object.scheduled_qty)
              // selected_product.quantity-workschedule_object.delivered_qty-workschedule_object.scheduled_qty
        setInputs(values =>({...values,
           
            ['order_quantity']:selected_product.quantity,
            ['balance_qty']:balanceQty
        }));
        document.getElementById('scheduled_qty').max = balanceQty;    
          })
          if(workschedule_object.is_name_req){
            document.getElementById(FIELD_CEMENT_NAME).disabled = false;
            document.getElementById(FIELD_CEMENT_NAME).required = true;
          }
          else{
            document.getElementById(FIELD_CEMENT_NAME).disabled = true;
            document.getElementById(FIELD_CEMENT_NAME).required = false;
          }
      }).catch(error => {
        setInProgress(false);
        displayError(error.response.data, "Loading Failed");
      });
    }
  }, []);
  

           
    const Reload = () => {
        window.location.reload();
      }      

      const view = () => {
        navigate('/WorkScheduleTable')
      }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")
             navigate(location.pathname, { replace: true });
        }
    return (
    <>
     {/* <div id="outer-container"  className="App" >  */}
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} > 
          <div id="header">
              <h3  className = "text font-weight-bold page-title" >WORK SCHEDULE EDIT FORM</h3>
          </div>
         
            <div className="container p-2"> 
             
           <FloatingControls tableLink="/WorkScheduleTable"  enableCancel={false}/>
            </div>
            <div className="container"> 
                <Row> 
                <Col xl={12} lg={12} md={6} >   
                    <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                    <h5 className='col-sm-11'><b>Order Details</b></h5><br/><br/>
                    <ViewOnlyTextbox 
                                        name="company_name" 
                                        value= {selectedSalesOrder?.company?.name|| ""}
                                        labelClassName="form-group col-sm-2 text-right"
                                        label="Company Name"
                                        inputClassName="form-control col-sm-3"
                                        />
                     <label htmlFor="salesorder_id" className="form-group col-sm-3 text-right"> Sales Order</label>
                        <select id="salesorder_id" name="salesorder_id" className="form-control col-sm-3"value={inputs.salesorder_id || ""} disabled={true}>
                        <option value=""></option>
                            {salesorders.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                        </select>
                                        <SummaryIcon onClickHandler={showSalesOrderDetails}/>
                                        <br/>

                                        <ViewOnlyTextbox 
                                        name="purchase_order_no" 
                                        value={selectedSalesOrder?.purchase_order_no || ""}
                                        labelClassName="form-group col-sm-2 text-right"
                                        label="Purchase Order No"
                                        inputClassName="form-control col-sm-3"
                                        />

                                        <ViewOnlyTextbox 
                                        name="billing_name" 
                                        value={selectedSalesOrder?.consignee?.customer?.name || ""}
                                        labelClassName="form-group col-sm-3 text-right"
                                        label="Billing Name"
                                        inputClassName="form-control col-sm-3"
                                        showSummaryDetails={showCustomerDetails}
                                        /> 
                                        <br/>

                                        <ViewOnlyTextbox 
                                        name="order_date" 
                                        value={selectedSalesOrder?.order_date || ""}
                                        labelClassName="form-group col-sm-2 text-right"
                                        label="Order Date"
                                        inputClassName="form-control col-sm-3"
                                        />  
                                        
                                        <ViewOnlyTextbox 
                                        name="ship_to" 
                                        value={selectedSalesOrder?.consignee?.name|| ""}
                                        labelClassName="form-group col-sm-3 text-right"
                                        label="Ship To"
                                        inputClassName="form-control col-sm-3"
                                        showSummaryDetails={showSiteDetails}
                                        />    
                       
                        
                    </div>
                </Col>

               
            </Row>
            
            {/* <div id="header">
                <h4  className = "text font-weight-bold page-title"><b>SCHEDULE DETAILS </b></h4>
            </div> */}
            <div className="container form-control-panel">
            <h5 style={{paddingTop:"20px"}}><b>SCHEDULE DETAILS </b></h5> 
            <Row> 
           
                <Col xl={6} lg={12} md={12}> 
                
                   <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel" >
                        
                        <label htmlFor="plant_id" className="form-group col-sm-4 text-right"> Plant Location: </label>
                        <select id="plant_id"name="plant_id" className="form-control col-sm-7  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.plant_id || ""}>
                        <option value="">Select plant</option>
                            {filteredplants.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                    </select>
     
                        <label htmlFor="sales_order_detail_id" className="form-group col-sm-4 text-right"> Production Grade</label>
                        <select id="sales_order_detail_id"name="sales_order_detail_id" className="form-control col-sm-7  browser-default custom-select  mandatory-form-control"  required onChange={  handleChangeProductDetails } value={inputs.sales_order_detail_id || ""}>
                        <option value="">Select Products</option>
                            {products.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                        </select>

                        <label htmlFor="delivery_mode" className="form-group col-sm-4 text-right">Mode of Delivery</label>
                        <select className="form-control col-sm-7 browser-default custom-select mandatory-form-control" required onChange={handleChange} value={inputs.delivery_mode || ""} name="delivery_mode"  id="delivery_mode">
                        {deliverymodes.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                                      ))}
                        </select>

                        
                        <label htmlFor="delivery_date" className="form-group col-sm-4 text-right">Delivery Date </label>
                        <input type="date" id="delivery_date"required name="delivery_date" onChange={handleChange} value={inputs.delivery_date || ""}className="form-control col-sm-7 mandatory-form-control"/>
                        <label htmlFor="delivery_time" className="form-group col-sm-4 text-right">Delivery Time </label>
                        <input type="time"id="delivery_time" name="delivery_time" required onChange={handleChange} value={inputs.delivery_time || ""}className="form-control col-sm-7 mandatory-form-control"/><br/>
                        <label htmlFor="scheduled_qty" className="form-group col-sm-4 text-right">Schedule Qty </label>
                        <input type="number" id="scheduled_qty"step="any" onChange={handleChange} value={inputs.scheduled_qty || ''}className="form-control col-sm-7 mandatory-form-control" required name="scheduled_qty" min="1" max ="4"/>
                        <label htmlFor="is_extra_shut_required" className="form-group col-sm-4 text-right">Is Extra Chute Required? </label>
                    <select className="form-control col-sm-7 browser-default custom-select" required onChange={handleChange} value={inputs.is_extra_shut_required || ""}name="is_extra_shut_required" id="is_extra_shut_required">
                        {/* <option value="">Please select</option> */}
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                   
           
                    <label htmlFor="cube_mould_test_place" className="form-group col-sm-4 text-right">Cube Mould Test @ </label>
                    <select id="cube_mould_test_place"className="form-control col-sm-7 browser-default custom-select"  onChange={handleChange} value={inputs.cube_mould_test_place || ""}   name="cube_mould_test_place">
                        {/* <option value="">Please select</option> */}
                        <option value="plant">Plant</option>
                        <option value="site">Site</option>
                        <option value="plant_and_site">Plant and Site</option>
                    </select>
                    
                    <label htmlFor="slump1" className="form-group col-sm-4 text-right">Slump @ Plant / Site</label>
                    <input type="number" name="slump1"  onChange={handleChange} value={inputs.slump1 || ""}  className="form-control col-sm-4 mandatory-form-control" required/>
                    <input type="number" name="slump2"  onChange={handleChange} value={inputs.slump2 || ""} className="form-control col-sm-3 mandatory-form-control" required/>     

                      <label htmlFor="is_name_req" className="form-group col-sm-4 text-right">Is Cement name to be displayed in DC? </label>
                        <select className="form-control col-sm-7 browser-default custom-select"id="is_name_req"onChange={handleChangeCementDisplyed} value={inputs.is_name_req || 'false'} required name="is_name_req">
                           {/* <option value=""></option> */}
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                       <label htmlFor="cement_name" className="form-group col-sm-4 text-right">Cement Name</label>
                        <input type="text" className="form-control col-sm-7"onChange={handleChange} value={inputs.cement_name || ""} id="cement_name"  name="cement_name" disabled={true} />       
                        
                    </div>   
                </Col>
              
                <Col xl={6} lg={12} md={12}> 
                
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel">
                        <label htmlFor="schedule_no" className="form-group col-sm-4 text-right">Job No</label>
                        <input type="text" className="form-control col-sm-7" id="schedule_no"value={inputs.schedule_no || ""} readOnly={true} style={{cursor: "not-allowed"}} name="schedule_no" disabled={true}/><br/>
                        <label htmlFor="schedule_date" className="form-group col-sm-4 text-right">Job Date</label>
                        <input type="date" className="form-control col-sm-7 mandatory-form-control" id="schedule_date"value={inputs.schedule_date || getCurrentDate()} name="schedule_date" required onChange={handleChange} /><br/>
                        
                        <label htmlFor="order_quantity" className="form-group col-sm-4 text-right">Order Qty.</label>
                        <input type="number" className="form-control col-sm-7" readOnly={true} style={{ cursor: "not-allowed"}}  name="order_quantity" disabled ={true}  value={inputs.order_quantity || ""}  />
                            {/* value="{{sales.soDOrdQty}}" */}
                        <label htmlFor="balance_qty" className="form-group col-sm-4 text-right">Balance Qty.</label>
                        <input type="number" value={inputs.balance_qty || ""} name="balance_qty" id="balance_qty" readOnly={true} className="form-control col-sm-7"  disabled ={true}  style={{ cursor: "not-allowed"}}/>
                        
                        <label htmlFor="validity_date" className="form-group col-sm-4 text-right">Validity Date </label>
                        <input type="date" id="validity_date" name="validity_date"  value={inputs.validity_date || ""} className="form-control col-sm-7"  onChange={handleChange} />
               
                </div>
                </Col>
            </Row>                                
            </div>
            </div>
                <div className="footer text-center">
              
                                
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> &nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>&nbsp;&nbsp;
                {!inputs.is_schedule_completed && 
                  (<><Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={onDeleteWorkSchedule}>Delete</Button></>)
                }

                
                </div>
        </form>
        </div>
    {/* </div> */}
         </>
    );
  }
  export default WorkScheduleEdit;